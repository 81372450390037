<template>
  <el-dialog
    title="站长结算"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
    <el-form-item label="站长" prop="agentId">
        <el-select
          placeholder="请选择站长代理"
          v-model="modalData.agentId"
          clearable
        >
          <el-option
            v-for="(item, index) in zzAgentList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="结算时间" prop="settleTime">
        <el-date-picker
            v-model="modalData.settleTime"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="结算时间"
          >
          </el-date-picker>
      </el-form-item>


    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submitAgent" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { mapState } from "vuex";
import { validatorForm } from "@/common/util";
export default {
  computed: mapState({
    zzAgentList(state) {
      return state.zzAgent.list || [];
    },
  }),
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType", "agentList"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        settleTime: [
          {
            required: true,
            message: "请选择结算时间",
            trigger: "change",
          },
        ],

        agentId: [
          {
            required: true,
            message: "请选择要结算的站点代理",
            trigger: "change",
          },
        ],

      },
    };
  },

  mounted() {
    this.$store.dispatch("fetchZZAgentListAll");
  },
  methods: {
    submitAgent() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
